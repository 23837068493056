

import { Link } from 'react-router-dom';

import Session from './Session';

function Header() {


  return (
    <header class="p-3 mb-3">
      <div class="container">
        <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <div className="w-100 mb-2">
            <a href="/" className="main-logo d-flex align-items-center link-body-emphasis text-decoration-none">
              Truck Stop 'N' Shop
            </a>
          </div>
          <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
              <li><Link className="nav-link px-2 link-secondary" to="/">Home</Link></li>
              <li><Link className="nav-link px-2 link-body-emphasis" to="/Catalog">Catalog</Link></li>
              <li><Link className="nav-link px-2 link-body-emphasis" to="/About">About</Link></li>
              <li><Link className="nav-link px-2 link-body-emphasis" to="/signin">Sign In</Link></li>
              <li><Link className="nav-link px-2 link-body-emphasis" to="/AccountCreation">Create Account</Link></li>
          </ul>
            <h4 style={{ color: 'white', marginRight: '10px' }}>{Session.getUsername()}</h4>
            <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
              <input type="search" className="form-control" placeholder="Search..." aria-label="Search" />
            </form>

          <div class="dropdown text-end">
            <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="https://github.com/mdo.png" alt="mdo" width="32" height="32" class="rounded-circle"/>
            </a>
              <ul className="dropdown-menu text-small">
                <li><a className="dropdown-item" href="#">New project...</a></li>
                <li><a className="dropdown-item" href="#">Settings</a></li>
                <li><a className="dropdown-item" href="#">Profile</a></li>
                <li><hr className="dropdown-divider" /></li>
                <li><a onClick={() => { Session.endSession() }} className="dropdown-item" href="#">Sign out</a></li>
              </ul>
            </div>
          </div>
      </div>
    </header>
  );
}

export default Header;


