import React from 'react';
import logo from './logo192.png';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import session from '../components/Session';


function SignIn() {

  const navigate = useNavigate();

  const authenticate = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await fetch('https://truckstopnshop.cpsc4911.com/api/authentication', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(Object.fromEntries(formData.entries()))
      });

      const result = await response.json();
      console.log(result);

      if (result.found === true) {

        session.setUsername(result.username);

        navigate('/');
      }
      else {
        alert("username or password is incorrect")
      }

    } catch (e) {
      console.error('Error:', e);
    }

  };


  return (
    <>
      <Header />
      <form id="signForm" onSubmit={authenticate}>
        <img className="mb-4" src={logo} alt="Logo" width="72" height="57" />
        <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

        <div className="form-floating">
          <input name="username" autoComplete='off' type="text" className="form-control" id="floatingInput" placeholder="name@example.com" />
          <label htmlFor="floatingInput">User Name</label>
        </div>
        <div className="form-floating">
          <input name="password" type="password" className="form-control" id="floatingPassword" placeholder="Password" />
          <label htmlFor="floatingPassword">Password</label>
        </div>

        <div className="form-check text-start my-3">
          <input className="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault" />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Remember me
          </label>
        </div>
        <button className="btn btn-primary w-100 py-2" type="submit">Sign in</button>
        <p className="mt-5 mb-3 text-body-secondary">© 2017–2024</p>
      </form>
    </>

  );
}

export default SignIn;
