var Session = (function() {

    var getUsername = function () {
        return localStorage.getItem("username") || "Guest";

    };

    var setUsername = function (name) {
        localStorage.setItem("username", name);
    };


    var getLoggedIn = function () {
        return localStorage.getItem("logged") || false;
    };

    var setLoggedIn = function (logged) {
        localStorage.setItem("logged", logged);

    };

    var endSession = function () {
        localStorage.removeItem("username");
        localStorage.removeItem("logged");
        window.location.reload();


    };

    return {
        getUsername: getUsername,
        setUsername: setUsername,
        getLoggedIn: getLoggedIn,
        endSession: endSession,
        setLoggedIn: setLoggedIn
    }
  
  })();
  
  export default Session;

