import React from 'react';
import Header from '../components/Header';

function App() {

    return (
        <>
            <Header />

            <h1 style={{textAlign: "center"}}>Home page</h1>

            
        </>
    );
}


export default App;
