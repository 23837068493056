import React from 'react';
import Header from '../components/Header';

function Catalog() {
  return (
    <>
      <Header />
      <h1 style={{textAlign: "center"}}>Dummy Catalog</h1>
    </>
  );
}

export default Catalog;