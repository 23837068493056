import React from 'react';
import logo from './logo192.png';
import Header from '../components/Header';


function AccountCreation() {



  const creation = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await fetch('https://truckstopnshop.cpsc4911.com/api/AccountCreation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(Object.fromEntries(formData.entries()))
      });

      const result = await response.json();

        alert(result.message);

    } catch (e) {
      console.error('Error:', e);
    }

  };

  return (
    <>
      <Header />

      <form id="signForm" onSubmit={creation}>
        <img className="mb-4" src={logo} alt="Logo" width="72" height="57" />
        <h1 className="h3 mb-3 fw-normal">Enter Account Information</h1>

        <div className="form-floating">
          <input name="username" autoComplete='off' type="text" className="form-control" id="floatingInput" placeholder="name@example.com" />
          <label htmlFor="floatingInput">User Name</label>
        </div>
        <div className="form-floating">
          <input name="password" type="password" className="form-control" id="floatingPassword" placeholder="Password" />
          <label htmlFor="floatingPassword">Password</label>
        </div>

        <button className="btn btn-primary w-100 py-2" type="submit">Create Account</button>
        <p className="mt-5 mb-3 text-body-secondary">© 2017–2024</p>
      </form>
    </>

  );
}

export default AccountCreation;
